import * as React from 'react'
import { Link } from 'gatsby'

import { Button, Layout, SEO } from 'components'

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Page Not found" />
      <div className="text-center py-16">
        <div className="font-bold text-5xl md:text-7xl lg:text-9xl">404</div>
        <div className="uppercase font-bold my-2">Page Not Found</div>
        <p className="mb-4">
          We can't seem to find the page you're looking for.
        </p>
        <Link to="/" className="uppercase">
          <Button>Go Back</Button>
        </Link>
      </div>
    </Layout>
  )
}

export default NotFoundPage
